export const site_name = 'Larochefoucauld Immobilier';
export const logo = '/images/next/Larochefoucauld_Logo-400.webp';

export const alt_logo = '/images/next/Larochefoucauld_monogram_blueoval.png';

export const meta_description = 'Agence immobilière sur la Côte basque. L’agence Larochefoucauld dénote et casse les codes&nbsp;! L’équipe prendra le temps de vous écouter, de comprendre vos motivations et d’analyser vos envies.';

export const colors =
{
	primary: '#002A3A'
};

export const js_version = '1.0.3'; // version du js

export const js_refresh_rate = { minutes: 30 }; // luxon Duration params

const config = { site_name, meta_description, logo, alt_logo, colors, js_version, js_refresh_rate };

export default config;