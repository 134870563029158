/* eslint-disable react/jsx-max-depth */
import { Link, NavLink  } from 'react-router-dom'

import Image from '../Pure/Image'

const networks =
[
	{
		url: 'www.facebook.com/Larochefoucauld-C%C3%B4te-Basque-Immobilier-108247378422883',
		label: 'Facebook',
		icon_url: '/images/next/svg/facebook-gold.svg'
	},
	{
		url: 'www.instagram.com/larochefoucauld_immobilier',
		label: 'Instagram',
		icon_url: '/images/next/svg/instagram-gold.svg'
	}
];

const links =
[
	{
		url: '/',
		label: 'Accueil'
	},
	{
		url: '/trouver-un-bien',
		label: 'Trouver un bien'
	},
	{
		url: '/notre-agence',
		label: 'Notre agence'
	},
	{
		url: '/nos-services',
		label: 'Nos services'
	},
	{
		url: '/estimer-mon-bien',
		label: 'Estimer mon bien'
	},
	{
		url: '/vendre-un-bien-immobilier',
		label: 'Vendre un bien immobilier'
	},
	{
		url: '/contact',
		label: 'Contact'
	}
];

export default function Footer ()
{
	const social_links = networks.map(function (infos)
	{
		return (
			<a key={ infos.url } href={ 'https://' + infos.url }
				target="_blank" rel="noreferrer"
				className="footer__noclass">
				{ infos.label }
			</a>
		);
	});

	const site_links = links.map(function (infos)
	{
		return (
			<NavLink key={ infos.url } to={ infos.url }
				target="_blank" rel="noreferrer"
				className="footer__site-link">
				{ infos.label }
			</NavLink>
		);
	});

	return (
		<div className="footer">

			<div className="footer__content">

				<div className="footer__row-1">

					<div className="footer__row1-left">

						<div className="footer__image-wrap">
							<Image className="footer__image-logo"
								src="/images/next/Larochefoucauld_Logo-1024x455.webp"
								alt="logo larochefoucauld immobilier"
							/>
						</div>

						<div className="footer__socials-links">
							{ social_links }
						</div>

					</div>

					<div className="footer__row1-right">
						<div className="footer__address">
							<h2>Adresse</h2>
							<span>
								44, Av. de Larochefoucauld
							</span>
							<span>
								64600 Anglet
							</span>
						</div>
						<div className="footer__hours">
							<h2>Horaires</h2>
							<span>
								Lundi – vendredi 09:00&nbsp;–&nbsp;19:00&nbsp;h
								<br />
							</span>
							<span>Samedi sur rendez-vous</span>
						</div>
					</div>
				</div>

				<div className="footer__row-2">
					<div className="footer__email">
						<h2>E-mail</h2>
						<a className="footer__email-link"
							href="mailto:agence@larochefoucauld.net">
							agence@larochefoucauld.net
						</a>
						<span className="u-flex-grow-1" />
					</div>
					<div className="footer__phone">
						<h2>Téléphone</h2>
						<a className="footer__phone-link"
							href="tel:+33559030670">
							05&nbsp;59&nbsp;03&nbsp;06&nbsp;70
						</a>
						<span className="u-flex-grow-1" />
					</div>
				</div>

				<div className="footer__row-3">
					<div className="footer__spacer" />

					{ site_links }

					<div className="footer__legal">
						<Link to="/mentions-legales"
							className="footer__legal-link">
							Mentions légales
						</Link>
					</div>
				</div>
			</div>

		</div>
	);
}
