import { Suspense } from 'react'

import { Await } from 'react-router-dom'

import RootSpinner from './Components/Spinners/RootSpinner'

import LayoutError from './Components/Errors/LayoutError'

import Loader from './Components/Next/utils/Loader'

export default function SuspendedLayout ({ resolve, Spinner, error, ...props })
{
	const VisibleSpinner = Spinner || Loader;

	return (
		<Suspense fallback={ <VisibleSpinner active /> }>

			<Await resolve={ resolve }
				errorElement={ <LayoutError /> }>
				{ page => props.children }
			</Await>

		</Suspense>
	);
}