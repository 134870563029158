import { meta_description, site_name } from '../app/shared_config.js'

const static_metas_configs =
[
	{
		route: '/',

		title: site_name,

		description: meta_description
	},
	{
		route: '/trouver-un-bien',

		title: 'Trouver un bien',

		description: 'Acheter un bien immobilier, c’est souvent bien plus qu’il n’y paraît. L’agence Larochefoucauld s’engage sur la transparence des biens pour que vous ayez la certitude d’avoir toutes les informations'
	},
	{
		route: '/notre-agence',

		title: 'Notre Agence',

		description: 'L’agence Larochefoucauld dénote et casse les codes !'
	},
	{
		route: '/nos-services',

		title: 'Nos services',

		description: 'Un accompagnement personnalisé : visite présentielle ou visio, présentation des biens, rédaction des actes et signature dématérialisée'
	},
	{
		route: '/estimer-mon-bien',

		title: 'Estimer mon bien',

		description: 'Bien estimer son bien immobilier, c’est avoir la garantie de le vendre. L’agence Larochefoucauld vous garantit un travail sérieux basé sur des références concrètes.'
	},
	{
		route: '/vendre-un-bien-immobilier',

		title: 'Vendre un bien immobilier',

		description: 'Larochefoucauld vous conseille comment et quand vendre tout en respectant la valeur réelle du bien lors de la mise en vente et selon ses caractéristiques'
	},
	{
		route: '/contact',

		title: 'Contact',

		description: 'Larochefoucauld vous conseille sur l\'achat et la vente de biens immobiliers.'
	}
];

export default static_metas_configs;