import Big from 'big.js'

Big.RM = Big.roundHalfUp;

export function isTrue (value)
{
	if (value === true) return true;

	return value === 'true';
}

export function isFalse (value)
{
	if (value === false) return true;

	return value === 'false';
}

export function capitalize (string)
{
	if (!string || typeof string !== 'string') return '';

	return string.slice(0, 1).toUpperCase() + string.slice(1);
}

export function removeTelCode (str='', CODE_DELIMITER='__')
{
	if (typeof str !== 'string') return str;

	if (str.includes(CODE_DELIMITER) === false) return str;

	return str.slice(str.indexOf(CODE_DELIMITER)+2);
}

export function formatRawTel (str='', CODE_DELIMITER='__')
{
	if (typeof str !== 'string' || !str) return str;

	if (!removeTelCode(str)) return '';

	const code = str.split(CODE_DELIMITER)[0];

	if (!code) return str;

	return code + ' ' + formatTel(removeTelCode(str));
}

export function formatTelWithZero (str='', CODE_DELIMITER='__')
{
	if (typeof str !== 'string' || !str) return str;

	if (!removeTelCode(str)) return '';

	const code = str.split(CODE_DELIMITER)[0];

	if (!code) return str;

	return '0' + removeTelCode(str);
}

const numbers_as_str = Array.from({ length: 10 }).map((_v, i) => String(i));

export function formatTel (str='', separator=' ')
{
	if (!str || typeof str !== 'string') return str;

	if (str.length === 1 && numbers_as_str.includes(str)) return str;

	return Array.from(str.split(' ').join(''))
	.filter(str => numbers_as_str.includes(str))
	.reduce(function (formatted, char, i)
	{
		if (i === 0) return char;
		if (i === 1) return formatted + separator + char;

		return formatted + (i % 2 !== 0 ? separator : '') + char;
	},
	'');
}

export function keepNumbersInStr (str='')
{
	if (typeof str !== 'string') return '';

	return Array.from(str)
	.filter(char => numbers_as_str.includes(char))
	.join('');
}

export const TIMES =
{
	'1 second': 1000,
	'1 minute': 1000 * 60,
	'1 hour': 1000 * 60 * 60,
	'1 day': 1000 * 60 * 60 * 24,
	'1 week': 1000 * 60 * 60 * 24 * 7,
	'1 month': 1000 * 60 * 60 * 24 * 30,
	'1 year': 1000 * 60 * 60 * 24 * 265
};

const numbers_list =
[
	'0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

/**
 * @param { any } input
 *
 * @returns { number } nombre correspondant à l'entrée, 0 si null ou non traitable
**/
export function getAsNumber (str='')
{
	if (typeof str === 'number') return str;

	if (typeof str === 'undefined' || !str || isNaN(str)) return 0;
	if (typeof str !== 'string') return 0;

	const safe_str = Array.from(str.split(',').join('.'))
	.filter(char => char === '.' || numbers_list.includes(char))
	.reduce(function (num, char)
	{
		if (num.includes('.') && char === '.') return num; // une seule virgule

		return num + char;
	},
	'');

	return Number(safe_str);
}

/**
 * @param { any } value input
 * @param { [number]} digits nombre de chiffres après la virgule
 * @param { [string] } round type d'arrondi (5 vers le haut ou le bas)
 *
 * @returns { number } rounded number
**/
export function num (value, digits=2)
{
	const as_number = getAsNumber(value);

	if (!as_number) return 0;

	if (Number.isInteger(as_number)) return as_number;

	const int_value = Math.floor(as_number);

	const big_decimals = new Big(as_number - int_value);

	return int_value + Number(big_decimals.round(digits));
}

/**
 * @param { any } input (si non entier, arrondi)
 *
 * @returns { string } nombre séparé par des espaces (ex 1 000 000)
**/
export function humanInt (value)
{
	const str = String(num(value, 0));

	const parts = Math.ceil(str.length / 3);

	return Array.from({ length: parts })
	.reduce(function (formatted, _v, i)
	{
		if (i > 0) formatted = ' ' + formatted;

		const end = str.length - (i*3);
		const start = end - 3 >= 0 ? end - 3 : 0;

		return str.slice(start, end) + formatted;
	},
	'');
}

/**
 * @param { any } input
 * @param { [string] } format format de l'affichage et de l'arrondi
 *
 * @returns { string } input formaté selon le format passé en paramètre
**/
export function formatNum (value, format='0,0[.]00')
{
	if (typeof format !== 'string') return String(value);

	const is__int = format.includes('.') === false;

	if (is__int)
	{
		return format.includes(',')
		? humanInt(value)
		: String(num(value, 0));
	}

	const optional_coma = format.includes('[.]');

	const digits_count = format.replace('[.]', '.')
	.slice(format.replace('[.]', '.').indexOf('.')+1)
	.length;

	if (digits_count > 0 === false) return humanInt(value);

	const value_as_number = getAsNumber(value);

	const int_value = Math.floor(value_as_number);

// on arrondi un chiffre après la précision pour arrondir au dessus si c'est un 5
	let decimal_value = num(value_as_number - int_value, digits_count+1);
	decimal_value     = num(decimal_value, digits_count);

	const decimals_as_int = Math.floor(decimal_value * (10 ** digits_count));

	let decimals = String(decimals_as_int).slice(0, digits_count);

	if (optional_coma && decimals_as_int === 0)
	{
		decimals = '';
	}
	else if (decimals.length < digits_count)
	{
		decimals += Array.from({ length: digits_count - decimals.length })
		.map((_v, i) => '0');
	}

	if (decimals) decimals = '.' + decimals;

	return humanInt(int_value) + decimals;
}

export function filenameFromPath (path='')
{
	const unix_path = path.split('\\').join('/');

	return unix_path.includes('/')
	? unix_path.slice(unix_path.lastIndexOf('/')+1)
	: unix_path;
}

export function someTime (timeout=1000)
{
	return new Promise(function (resolve, reject)
	{
		window.setTimeout(resolve, timeout);
	});
}

export function isSimpleObject (value)
{
	if (typeof value === 'undefined') return false;

	if (value === null) return false;

	if (Array.isArray(value)) return false;

	return typeof value === 'object'
	? value.constructor.name === 'Object'
	: false;
}

export function removeFileExt (file)
{
	if (!file || typeof file !== 'string') return file;

	return file.includes('.')
	? file.slice(0, file.lastIndexOf('.'))
	: file;
}

export function getTruncatedText (text, max_length, suspension_points)
{
	var short = text;

	if (typeof text !== 'string') return '';

	if (short.length < max_length) return text;

	var accumulator = '';

	short = short
	.split('.')
	.filter(function (chunk)
	{
		if (!accumulator || (accumulator.length + chunk.length < max_length))
		{
			accumulator += chunk + '.';
			return true;
		}
	})
	.join('.');

	accumulator = '';

	if (short === '')
	{
		short = text
		.split(' ')
		.filter(function (chunk)
		{
			if (accumulator.length + chunk.length < max_length)
			{
				accumulator += chunk + ' ';
				return true;
			}
		})
		.join(' ');
	}

	if (short === '')
	{
		short = text.slice(0, max_length);
	}
	else
	{
		short = short.slice(0, max_length);
	}

	if (suspension_points && short.length < text.length)
	{
		short += '...';
	}

	return short;
}