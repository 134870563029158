import { Suspense } from 'react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { PrimeReactProvider } from 'primereact/api'

import AuthLayout  from './Components/Layouts/AuthLayout'
import BackLayout  from './Components/Layouts/BackLayout'
import FrontLayout from './Components/Layouts/FrontLayout'

import NotFoundPage from './Components/Errors/Pages/NotFoundPage'
import RouterError  from './Components/Errors/RouterError'

import ContactFormLoader from './Components/Loaders/ContactFormLoader'
import PropertyLoader    from './Components/Loaders/PropertyLoader'
import LayoutLoader      from './Components/Loaders/LayoutLoader'
import ReviewLoader      from './Components/Loaders/ReviewLoader'

import RootSpinner from './Components/Spinners/RootSpinner'

import AppVersion from './Components/Configs/AppVersion'

import ROUTES from './routes'

const prime_params = { ripple: true, unstyled: true };

const routes =
[
	{
		path: '*',
		errorElement: RouterError,
		element: <NotFoundPage error="Cette page n'existe pas." center />
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <FrontLayout />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.HomePage
			},
			{
				path: 'notre-agence',
				lazy: ROUTES.AgencyPage
			},
			{
				path: 'nos-services',
				lazy: ROUTES.ServicesPage
			},
			{
				path: 'estimer-mon-bien',
				lazy: ROUTES.PropertyValuationPage
			},
			{
				path: 'vendre-un-bien-immobilier',
				lazy: ROUTES.SellPropertyPage
			},
			{
				path: 'contact',
				lazy: ROUTES.ContactPage
			},
			{
				path: 'mentions-legales',
				lazy: ROUTES.LegalNoticePage
			},
			{
				path: 'trouver-un-bien',
				lazy: ROUTES.AllPropertiesPage
			},
			{
				path: 'bien/:slug',
				lazy: ROUTES.PropertyPage,
				loader: PropertyLoader
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <AuthLayout />,

		children:
		[
			{
				path: 'login',
				lazy: ROUTES.LoginPage
			},
			{
				path: 'mot-de-passe-oublie',
				lazy: ROUTES.RequestPasswordPage
			},
			{
				path: 'mot-de-passe-oublie/nouveau-mot-de-passe/:token',
				lazy: ROUTES.NewPasswordPage
			}
		]
	},

	{
		path: 'mon-compte',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.MyAccountPage
			}
		]
	},

	{
		path: '/admin',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: 'accueil',
				lazy: ROUTES.AdminHomePage
			},
			{
				path: 'liste-des-utilisateurs',
				lazy: ROUTES.UsersListPage
			},
			{
				path: 'liste-des-utilisateurs/nouvel-utilisateur',
				lazy: ROUTES.NewUserPage
			},
			{
				path: 'liste-des-utilisateurs/:user_id',
				lazy: ROUTES.UserPage
			},
			{
				path: 'demandes-de-contact',
				lazy: ROUTES.ContactFormsListPage
			},
			{
				path: 'demandes-de-contact/:id',
				lazy: ROUTES.ContactFormPage,
				loader: ContactFormLoader
			},
			{
				path: 'liste-des-temoignages',
				lazy: ROUTES.ReviewsListPage
			},
			{
				path: 'liste-des-temoignages/nouveau',
				lazy: ROUTES.NewReviewPage
			},
			{
				path: 'liste-des-temoignages/:id',
				lazy: ROUTES.UpdateReviewPage,
				loader: ReviewLoader
			}
		]
	}
];

const router = createBrowserRouter(routes);

export default function App ()
{
	return (
		<PrimeReactProvider value={ prime_params }>

			<Suspense fallback={ <RootSpinner active /> }>
				<RouterProvider router={ router } />
			</Suspense>

			<AppVersion />

		</PrimeReactProvider>
	);
}