import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'

export default function ReviewLoader ({ params })
{
	return defer({ review: getReview(true, params), params });
}

async function getReview (retry=true, params)
{
	resetForm('Review');

	try
	{
		var { data=[] } = await request('/reviews/get')
		.post({ id: params.id || '-1' });

		var review = data?.[0];
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		review = await getReview(false, params);
	}

	FormsStore.update(function (store)
	{
		store['Review'] =
		{
			'id': review._id,
			'review[author]': review.author,
			'review[text]': review.text
		};
	});

	return review;
}