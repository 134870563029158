import { defer } from 'react-router-dom'

import striptags from 'striptags'

import request from '../Functions/ajax/request'

import { getTruncatedText, someTime } from '../../../shared/utilities'

import MetasStore from '../../Stores/MetasStore'

import GLOBAL_CONFIG from '../../shared_config'

export default async function PropertyLoader ({ params={ } })
{
	return defer({ property: getProperty(true, params) });
}

async function getProperty (retry=true, params)
{
	MetasStore.update(store => { store.title = '' });

	try
	{
		var { property } = await request('/front/get-property')
		.post({ slug: params.slug });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.error)
		{
			MetasStore.update(store => { store.title = 'Bien introuvable.' });

			throw { error: 'Ce bien est introuvable.' };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		property = await getProperty(false, params);
	}

	if (property)
	{
		MetasStore.update(setMetas);
	}

	return property;

	function setMetas (metas)
	{
		const infos = property ? property.data : undefined;

		if (!infos) return metas;

		metas.title =
		[
			(infos.description_title || { }).fr || '',
			GLOBAL_CONFIG.site_name
		]
		.filter(str => str)
		.join(' - ') || GLOBAL_CONFIG.site_name;

		let description = striptags((infos.description || { }).fr ||
			GLOBAL_CONFIG.description
		);

		description = getTruncatedText(description, 160);

		metas.description = description;

		metas.og_title = (infos.description_title || { }).fr || metas.title;

		metas.og_description = description;

		const img = property?.images?.[0];

		if (img?.url)
		{
			metas.og_image = img.url.startsWith('http')
			? img.url
			: document.location.origin + img.url;
		}
	}
}