import { Store } from 'pullstate'

import { site_name, meta_description } from '../shared_config'

const default_store =
{
	title: document.getElementsByTagName('title')?.[0]?.textContent,
	description: document.querySelector('meta[name="description"')?.getAttribute?.('content')
};

default_store.title ||= site_name;
default_store.description ||= meta_description;

const open_graph = ['title', 'type', 'image', 'url', 'description', 'site_name'];

open_graph.forEach(function (field)
{
	default_store['og_' + field] = document
	.querySelector('meta[property="og:' + field + '"')?.getAttribute?.('content');
});

default_store.og_description ||= default_store.description;

const MetasStore = new Store(default_store);

export default MetasStore;