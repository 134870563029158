const DeletedPage = () => 'Page supprimée.';

export const HomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/HomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AdminHomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/accueil/AdminHomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactFormsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/demandes-de-contact/ContactFormsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactFormPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/demandes-de-contact/[id]/ContactFormPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ReviewsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-temoignages/ReviewsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewReviewPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-temoignages/nouveau/NewReviewPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateReviewPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-temoignages/[id]/UpdateReviewPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UsersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/UsersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/nouvel-utilisateur/NewUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/[user_id]/UserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PropertyPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/bien/[slug]/PropertyPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ContactPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/contact/ContactPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PropertyValuationPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/estimer-mon-bien/PropertyValuationPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const LoginPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/login/LoginPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const LegalNoticePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mentions-legales/LegalNoticePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyAccountPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mon-compte/MyAccountPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RequestPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/RequestPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/nouveau-mot-de-passe/[token]/NewPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ServicesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/nos-services/ServicesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AgencyPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/notre-agence/AgencyPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AllPropertiesPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/trouver-un-bien/AllPropertiesPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const SellPropertyPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/vendre-un-bien-immobilier/SellPropertyPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

const ROUTES = { HomePage, AdminHomePage, ContactFormsListPage, ContactFormPage, ReviewsListPage, NewReviewPage, UpdateReviewPage, UsersListPage, NewUserPage, UserPage, PropertyPage, ContactPage, PropertyValuationPage, LoginPage, LegalNoticePage, MyAccountPage, RequestPasswordPage, NewPasswordPage, ServicesPage, AgencyPage, AllPropertiesPage, SellPropertyPage };
export default ROUTES;