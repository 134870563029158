import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

export default function ScrollTopOnRouteChange ()
{
	const { pathname } = useLocation();

	useEffect(function ()
	{
		window.scrollTo(0, 0);
	},
	[pathname]);

	return null;
}