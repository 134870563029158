import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import MetasStore from '../../Stores/MetasStore'

import static_metas_configs from '../../../shared/static_metas_config'

import GLOBAL_CONFIG from '../../shared_config'
import { getTruncatedText } from '../../../shared/utilities'

export default function FrontSEO ()
{
	const location = useLocation();

	const metas = MetasStore.useState(store => store);

	useEffect(function ()
	{
		const html =
		{
			title: document.getElementsByTagName('title')?.[0],
			description: document.querySelector('meta[name="description"]'),

			og_title: document.querySelector('meta[property="og:title"]'),
			og_type: document.querySelector('meta[property="og:type"]'),
			og_image: document.querySelector('meta[property="og:image"]'),
			og_url: document.querySelector('meta[property="og:url"]'),
			og_description: document.querySelector('meta[property="og:description"]'),
			og_site_name: document.querySelector('meta[property="og:site_name"]')
		};

		Object.keys(html).filter(name => html[name] && metas[name])
		.forEach(function (name)
		{
			const node = html[name];

			if (name === 'title')
			{
				if (metas.title)
				{
					node.textContent = metas.title;
				}
			}
			else // else if (name.startsWith('og'))
			{
				node.setAttribute('content', metas[name]);
			}
		});
	},
	[metas]);

	useEffect(function ()
	{
		const static_metas = static_metas_configs
		.find(infos => infos.route === document.location.pathname);

		if (!static_metas) return () => void 0;

		MetasStore.update(function (store)
		{
			store.title =
			[
				static_metas.title
			]
			.filter(str => str)
			.join(' - ') || GLOBAL_CONFIG.site_name;

			const description = getTruncatedText(static_metas.description, 160);

			store.description = description;

			if (static_metas.image)
			{
				store.og_image = static_metas.image.startsWith('http')
				? static_metas.image
				: document.location.origin + static_metas.image;
			}

			store.og_title = static_metas.title;

			store.og_description = description;

			return store;
		});
	},
	[location]);

	return null;
}